import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';

import WithAnimation from '@components/Common/WithAnimation';

import { THE_BRIDE } from '@/constants';
import { BG_DESKTOP_VIEW } from '@/constants/assets';

import useLang from '@hooks/useLang';
import useInvitation from '@hooks/useInvitation';

function LeftContent() {
  const isInvitation = useInvitation();
  const lang = useLang();

  return (
    <Box
      bgColor="bgPrimary"
      width="calc(100% - 500px)"
      height="100%"
      pos="fixed"
      bgImage={BG_DESKTOP_VIEW}
      bgSize="cover"
      padding="42px"
      borderRight="8px solid"
      borderRightColor="blackAlpha.400"
      boxShadow="inset 0 0 0 2000px rgb(0 0 0 /10%)"
    >
      <WithAnimation>
        <Text letterSpacing="2px" fontSize="md" color="white">
          {isInvitation ? 'THE WEDDING OF' : 'WEDDING ANNOUNCEMENT'}
        </Text>
      </WithAnimation>
      <WithAnimation>
        <Heading
          margin="32px 0"
          fontWeight="normal"
          size="4xl"
          letterSpacing="2px"
          color="white"
          dangerouslySetInnerHTML={{ __html: THE_BRIDE }}
        />
      </WithAnimation>
      <WithAnimation>
        <Text fontStyle="italic" maxWidth="500px" color="white" letterSpacing="1px">
          {lang ==="id"
            ?`Mahasuci (Allah) yang telah menciptakan semuanya berpasang-pasangan, baik dari apa yang ditumbuhkan 
            oleh bumi dan dari diri mereka sendiri, maupun dari apa yang tidak mereka ketahui.`
            :`Exalted is He who created all pairs, from what the earth grows and from themselves and from that which they do not know.`
          } <br />
          - Q.S. Yasin: 36 -
        </Text>
      </WithAnimation>
    </Box>
  );
}

export default LeftContent;